<template>
  <AModal
    :class="['ext-modal-wallet', { white }]"
    centered
    v-model:open="open"
    title=""
    :closable="false"
    :footer="false"
  >
    <div class="ext-modal-title">
      <span>Connect Wallet</span>
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        @click="open = false"
      >
        <path
          d="M10.5 1.5L1.5 10.5M1.5 1.5L10.5 10.5"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div class="ext-modal-line"></div>
    <div class="ext-modal-content">
      <div
        :class="[
          'ext-modal-content-item',
          {
            loading
          }
        ]"
        @click="handleChangeWallet('MetaMask')"
      >
        <div class="connect-wallet-info">
          <img :src="MetamaskIcon" alt="" srcset="" />
          <span>MetaMask</span>
        </div>
        <i>
          <template v-if="isMetaMask">
            <whiteIcon v-if="white" />
            <blackIcon v-else />
          </template>

          <rightIcon />
        </i>
      </div>
      <div
        :class="[
          'ext-modal-content-item',
          {
            loading
          }
        ]"
        @click="handleChangeWallet('OKX')"
      >
        <div class="connect-wallet-info">
          <img :src="OKXIcon" alt="" srcset="" />
          <span>OKX Wallet</span>
        </div>
        <i>
          <template v-if="isOkxwallet">
            <whiteIcon v-if="white" />
            <blackIcon v-else />
          </template>
          <rightIcon />
        </i>
      </div>

      <div
        :class="[
          'ext-modal-content-item',
          {
            loading
          }
        ]"
        @click="handleChangeWallet('Bitget')"
      >
        <div class="connect-wallet-info">
          <img :src="BitgetIcon" alt="" srcset="" />
          <span>Bitget Wallet</span>
        </div>
        <i>
          <template v-if="isBitget">
            <whiteIcon v-if="white" />
            <blackIcon v-else />
          </template>
          <rightIcon />
        </i>
      </div>

      <div
        :class="[
          'ext-modal-content-item',
          {
            loading
          }
        ]"
        @click="handleChangeWallet('Gate')"
      >
        <div class="connect-wallet-info">
          <img :src="GateIcon" alt="" srcset="" />
          <span>Gate Wallet</span>
        </div>
        <i>
          <template v-if="isGate">
            <whiteIcon v-if="white" />
            <blackIcon v-else />
          </template>
          <rightIcon />
        </i>
      </div>
    </div>
  </AModal>
</template>

<script setup>
import { ref, inject, onBeforeUnmount, watch } from 'vue'
import { Modal as AModal } from 'ant-design-vue'
import { useLocalStorage } from '../hooks/useLocalStorage.js'

import BitgetIcon from '../assets/wallet/Bitget.png'
import GateIcon from '../assets/wallet/Gate.png'
import MetamaskIcon from '../assets/wallet/Metamask.png'
import OKXIcon from '../assets/wallet/OKX.png'

import blackIcon from '../assets/wallet/vue/black.vue'
import whiteIcon from '../assets/wallet/vue/white.vue'
import rightIcon from '../assets/wallet/vue/right.vue'

function decToHex(decimal) {
  return '0x' + decimal.toString(16)
}

function hexToStr(hex) {
  return parseInt(hex.slice(2), 16)
}

defineProps({
  white: Boolean
})

const { value: walletName, setValue } = useLocalStorage('walletType', '')

const account = inject('account')

const checkConnection = inject('checkConnection')

const { chainConfig } = inject('config')

const wagmiConfig = inject('wagmiConfig')

const open = defineModel('open', { type: Boolean })

const loading = ref(false)

const isMetaMask = ref(window?.ethereum)
const isOkxwallet = ref(window?.okxwallet)
const isBitget = ref(window.bitkeep?.ethereum)
const isGate = ref(window?.gatewallet)

// 监听弹窗打开，重新检查钱包状态
watch(open, (newVal) => {
  if (newVal) {
    isMetaMask.value = window?.ethereum
    isOkxwallet.value = window?.okxwallet
    isBitget.value = window.bitkeep?.ethereum
    isGate.value = window?.gatewallet
  }
})

const chainId = ref(null)

// 获取顶层 window 对象
const topWindow = window.top || window

// 钱包类型映射
const walletMap = {
  MetaMask: topWindow.ethereum,
  OKX: topWindow.okxwallet,
  Bitget: topWindow.bitkeep?.ethereum,
  Gate: topWindow.gatewallet
}

// 使用对象映射来优化下载链接的选择
const walletLinks = {
  MetaMask: 'https://metamask.io/download',
  OKX: 'https://www.okx.com/download',
  Bitget: 'https://web3.bitget.com/wallet-download',
  Gate: 'https://www.gate.io/web3'
}

// 通用函数：检查链ID并切换链
async function checkChainID(chainid, provider) {
  if (!provider) return

  let targetChainid = wagmiConfig.chains[0].id
  const hexChainid = decToHex(targetChainid)

  console.log('checkChainID:', chainid, 'targetChainid:', targetChainid)

  if (chainid !== targetChainid) {
    try {
      const info = await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: hexChainid }]
      })
      console.log('switchChain:', info)
    } catch (switchError) {
      console.log('switchError:', switchError.code)

      if (switchError.code === 4902) {
        const params = {
          chainId: hexChainid,
          chainName: chainConfig.name,
          rpcUrls: chainConfig.rpcUrls.default.http,
          nativeCurrency: chainConfig.nativeCurrency,
          blockExplorerUrls: [chainConfig.blockExplorers.default.url]
        }
        await provider.request({ method: 'wallet_addEthereumChain', params: [params] })
      }
    }
  }
}

// 通用的连接钱包函数
async function connectWallet(provider, walletType) {
  if (!provider) {
    // 如果钱包类型不在映射中，则不执行打开链接
    const downloadLink = walletLinks[walletType]
    if (downloadLink) {
      window.open(downloadLink, '_blank') // 打开下载链接
    }
    return
  }

  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'connect_wallet',
      wallet: walletType,
      page_name: 'Bridge Page'
    })
  }

  try {
    const accounts = await provider.request({ method: 'eth_requestAccounts' })
    account.value = accounts[0]
    setValue(account.value ? walletType : '')
    chainId.value = await provider.request({ method: 'eth_chainId' })
    console.log(`${walletType} 连接钱包:`, chainId.value)
    checkChainID(hexToStr(chainId.value), provider)
  } catch (error) {
    console.error(`${walletType} 连接失败:`, error)
  }
}

// 钱包切换处理
async function handleChangeWallet(wallet) {
  if (wallet === 'MetaMask') connectWallet(topWindow.ethereum, 'MetaMask')
  if (wallet === 'OKX') connectWallet(topWindow.okxwallet, 'OKX')
  if (wallet === 'Bitget') connectWallet(topWindow.bitkeep?.ethereum, 'Bitget')
  if (wallet === 'Gate') connectWallet(topWindow.gatewallet, 'Gate')
  open.value = false
}

// 监听钱包事件
function listenerWallet(provider, walletType) {
  if (provider) {
    provider.on('accountsChanged', (accounts) => {
      account.value = accounts[0]
      setValue(account.value ? walletType : '')
    })
    provider.on('disconnect', (res) => {
      console.log(`${walletType} disconnect`, res)
    })
    provider.on('chainChanged', (chainId) => {
      console.log(`${walletType} chainChanged`, chainId, walletName.value)
      if (walletName.value === walletType) {
        checkChainID(hexToStr(chainId), provider)
      }
    })
  }
}

function initWallet() {
  listenerWallet(topWindow.ethereum, 'MetaMask')
  listenerWallet(topWindow.okxwallet, 'OKX')
  listenerWallet(topWindow.bitkeep?.ethereum, 'Bitget')
  listenerWallet(topWindow.gatewallet, 'Gate')

  // 判断是否在手机端
  const isMobile = window.innerWidth <= 768

  // 如果是手机端，自动连接钱包
  if (isMobile) {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'connect_wallet',
        wallet: 'mobile',
        page_name: 'Bridge Page'
      })
    }
    checkConnection()
  } else {
    // 非手机端，按需连接
    if (walletName.value && ['MetaMask', 'OKX', 'Bitget', 'Gate'].includes(walletName.value)) {
      connectWallet(walletMap[walletName.value], walletName.value)
    }
  }
}

initWallet()

onBeforeUnmount(() => {
  const providers = Object.values(walletMap)
  providers.forEach((provider) => {
    if (provider) provider.removeAllListeners()
  })
  console.log('组件卸载')
})
</script>
<style lang="scss">
.ext-modal-wallet.white {
  .ext-modal-title {
    span {
      color: var(--colors-text-text-primary-900, #1f1f1f);
    }
  }
  .ant-modal-content {
    background: var(--Colors-Background-bg-primary, #fff);
  }
  .ext-modal-content-item {
    background: #f8f8f8;
    .connect-wallet-info {
      color: var(--colors-text-text-primary-900, #1f1f1f);
    }
  }
}
.ext-modal-wallet {
  .ant-modal-content {
    display: flex;
    margin: 0 auto;
    width: 360px;
    position: relative;
    @media screen and (max-width: 800px) {
      width: 358px;
    }
    padding: var(--spacing-2xl, 20px) var(--spacing-lg, 12px) var(--spacing-xl, 16px)
      var(--spacing-lg, 12px);
    border-radius: var(--radius-4xl, 24px);

    background: var(--Colors-Background-bg-secondary, #111);

    /* Shadows/shadow-lg */
    box-shadow:
      0px 12px 16px -4px var(--Colors-Effects-Shadows-shadow-lg_01, rgba(16, 24, 40, 0.08)),
      0px 4px 6px -2px var(--Colors-Effects-Shadows-shadow-lg_02, rgba(16, 24, 40, 0.03));
    .ant-modal-body {
      flex: 1;
    }
  }
  .ext-modal-title {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: var(--spacing-lg, 12px);
    text-align: center;
    font-family: Inter;
    line-height: 150%; /* 21px */
    font-style: normal;
    position: relative;
    span {
      color: var(--colors-text-text-primary-900, #f5f5f5);
      font-size: 18px;
      font-weight: 700;
    }
    svg {
      position: absolute;

      right: 20px;

      cursor: pointer;
    }
  }
  .ext-modal-line {
    width: 100%;
    height: 1px;
    margin: 12px 0px;
  }
  .ext-modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    &-item {
      width: 100%;
      padding: 10px var(--spacing-lg, 12px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      border-radius: var(--radius-xl, 12px);
      background: #171717;

      i {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: var(--spacing-sm, 4px);
        svg {
          &:first-child {
            display: block;
          }
          &:last-child {
            display: none;
          }
        }
      }
      &:hover {
        cursor: pointer;
        i {
          svg {
            &:first-child {
              display: none;
            }
            &:last-child {
              display: block;
            }
          }
        }
      }
      &.loading {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
  .connect-wallet-info {
    display: flex;
    align-items: center;
    gap: var(--spacing-md, 8px);
    color: var(--Colors-Text-text-primary, #f5f5f5);

    /* Desktop/Body/Content SemiBlod */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    letter-spacing: 0.16px;
    img {
      width: 40px;
      height: 40px;
    }
  }
}
</style>
<style>
.ant-modal-root .ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.65);
}
</style>
